import { GemstoneType, GemstoneLevel, Rarity } from "util/items";

// other proxies:
// https://codetabs.com/cors-proxy/cors-proxy.html
// https://corsproxy.github.io/

// const proxy = 'https://cors.bridged.cc';
// const proxy = 'https://cors-anywhere.herokuapp.com';
// const proxy = 'https://api.codetabs.com/v1/proxy/?quest=';
// const mfapi = 'http://mfp.makingfun.com/api';
// const url = (path: string) => `${proxy}/${mfapi}/${path}`;
// const url = (path: string) => `${proxy}${mfapi}/${path}`;
const mfapi = 'https://eternium-mfp.alex-tsarkov.workers.dev/api'
const url = (path: string) => `${mfapi}/${path}`;

export type Account = {
  username: string
  emailAddress: string
  emailAddressVerified: boolean
  apps: Array<{ name: string }>
}

// const headersObj = (apiKey: string): object => { return { headers: { 'X-API-KEY': apiKey } } };

// // requires API key
// export async function getAccount(email: string, apiKey: string = defaultApiKey): Promise<Account> {
//   const response = await fetch(url(`accounts/${email}`), headersObj(apiKey));
//   return await response.json();
// }

// reload leaderboards every minute
const leaderboardReloadMillis = 1000 * 60;
// requires API key if not for proxy
export async function getLeaderboards(): Promise<Array<Leaderboard>> {
  const localStorageKey = 'lastLeaderboardReload';
  const lastLeaderboardReload = Number(localStorage.getItem(localStorageKey) ?? '0');
  const now = Date.now();
  const useCache = lastLeaderboardReload + leaderboardReloadMillis > now;
  const cacheHeaders: { cache?: RequestCache } = useCache ? { cache: 'force-cache' } : {};
  const response = await fetch(url('leaderboards'), cacheHeaders);
  const results = await response.json();
  if (!useCache) {
    localStorage.setItem(localStorageKey, now.toString());
  }
  return results;
}

export type ChallengerRanking = {
  leaderboardId: string
  // this is what we're really after
  ranking: number
  score: number
}

export async function getChallengerRanking(leaderboardId: string, challenger: string): Promise<ChallengerRanking> {
  const response = await fetch(url(`leaderboards/${leaderboardId}/rankings/${challenger}`));
  return await response.json();
}

export type Leaderboard = {
  id: string
  name: string
  type?: string
  description?: string
  gameId: string
  entriesCount: number
  // subLeaderboards: []
}

export async function getLeaderboard(board: string): Promise<Leaderboard> {
  const response = await fetch(url(`leaderboards/${board}`));
  return await response.json();
}

export type ChampionPoints = {
  abilityRating: number
  armorPercent: number
  criticalDamage: number
  criticalRating: number
  experience: number
  extraGold: number
  haste: number
  lifeOnHit: number
  lifePercent: number
  lifeRegen: number
  movementSpeed: number
  power: number
}

export enum HeroClass {
  Warrior = "Warrior",
  Mage = "Mage",
  BountyHunter = "Bounty Hunter",
}

export type AbilityLevel = {
  bash: number
  battle_rage: number
  celerity: number
  cleave: number
  deadly_throw: number
  devastate: number
  duelist: number
  endurance: number
  fleetfooted: number
  frenzy: number
  in_the_zone: number
  intense_training: number
  leap: number
  lightning_reflexes: number
  power_infusion: number
  shield_block: number
  shockwave_warrior: number
  sword_and_board: number
  toughasnails: number
  warrior_charge: number
  whirlwind: number
}

export type Bonus = {
  amount: number,
  stat: string,
}

export type BonusStatsEnchant = {
  params: {
    bonuses: Array<Bonus>
    enchantID: string
  }
  type: 'BonusStats'
}

export type SpecialEnchant = {
  params: {
    chance: number
    // effect description
    description: string
    effectType: number
    // name
    enchantID: string
    gate_chance: number
    params: {
      duration: number
      effectIcon: string
      maxCharges: number
      maxStack: number
      numCharges: number
      receivedDamageMultiplier: number
      uniqueID: string
    }
    ppm0: number
    ppm1: number
    proc_type: string
  }
  type: string
}

/*
// TODO - unique - this one is IM
export type SpecialEffect = {
  params: {
    amount: number,
    chance: number,
    customID: string,
    duration: number,
    icd: number,
    maxStack: number,
    proc_type: string
  }
  type: string
}

// ToS
export type EffectProc = {
  params: {
    customID: string
    description: string
    effectType: number
    params: {
      bonusCriticalDamage: number
      duration: number,
      effectIcon: string,
      uniqueID: string
    }
    ppmID: string,
    proc_type: string
  },
  type: "EffectProc"
}
*/

export type UniqueOrSetThing = {
  type: string
}

export type GearEffect = SpecialEnchant | BonusStatsEnchant | UniqueOrSetThing

export type Socket = {
  ID: string
  gemstone: {
    type: GemstoneType
  },
  itemLevel: GemstoneLevel,
  slotI: number,
  slotJ: number,
}

export type Upgrades = Array<string | number>

export enum EquippedSlot {
  Head = "head",
  Necklace = "necklace",
  Shoulders = "shoulders",
  Chest = "chest",
  Cape = "cape",
  Trinket = "trinket",
  Gloves = "gloves",
  Bracer = "bracer",
  Belt = "belt",
  Pants = "pants",
  Boots = "boots",
  Ring = "ring",
  MainHand = "main_hand",
  OffHand = "off_hand",
}

export type Equipped = {
  ID: string
  boundID?: string
  celestial?: boolean
  MOR: number
  ci: Array<number>
  color: number
  colorTexture: string
  cosmeticEffect?: string
  decalColor: number
  ephemeral: boolean
  equippedSlot: EquippedSlot
  gearEffects: Array<GearEffect>
  glowColor: number
  itemClass: string
  // ilvl
  itemLevel: number
  levelRequirement: number
  modelVariation: number
  // item name, i.e. Warlord's Cap
  name: string
  overwriteSkin: string
  // rarity
  quality: Rarity
  reforgeCount?: number
  reforgedStat?: string,
  seed: number
  skin: string
  slotI: number
  slotJ: number
  sockets?: Array<Socket>
  source?: string,
  textureVariation: string
  upgrades: Upgrades
  version: number
}

export type Dice = {
  // array of expected (7 entries, 71 - 77)
  e: Array<number>
  // array of had (7 entries, 71 - 77)
  h: Array<number>
  // number of crafts
  nRolls: number
  seed: number
  version: number
}

export enum MedalId {
  Fortitude = 'fortitude',
  Glory = 'glory',
  Heroism = 'heroism',
  Knowledge = 'knowledge',
  Tenacity = 'tenacity',
  Vigor = 'vigor',
}

export type Medal = {
  level: number
  medalID: MedalId
}

export enum QuickbarAbilityType {
  Attack = "attack",
  Utility = "utilty",
  Passive = "passive",
}

export type QuickbarAbility = {
  ability: string
  locked: boolean
  type: QuickbarAbilityType
}

export type Companion = {
  level: number
  type: string
}

export type Hero = {
  DPS: number
  Recovery: number
  Toughness: number
  abilityLevel: AbilityLevel
  class: HeroClass
  equipped: Array<Equipped>
  gender: string
  handle: string
  heroCreationDateEpoch: number
  // hero id, HERO-some stuf
  hero_id: string
  // hero level
  level: number
  lootUpgradeDice: Dice
  // crafting bag!
  masterCraftDice: Dice
  // medals
  medalsInfo: Array<Medal>
  // hero name
  name: string,
  // quickbar
  quickbar: Array<QuickbarAbility>
  selectedPlayerNameID: string
  // companions
  team: Array<Companion>
}

export type DamageFromSource = {
  avg: number
  dps: number
  max: number
  min: number
  percent: number
  source: string
  total: number
}

export type PhaseInfo = {
  avgDamage: number
  dps: number
  // could be good to display boss vs clear
  duration: number
  hpDestroyed: number
  maxDamage: number
  minDamage: number
  numAttacks: number
  t0: number
  t1: number
  totalDamage: number
}

// buncha good stuff
export type TrialStats = {
  boss: PhaseInfo
  damageMeter: Array<DamageFromSource>
  // no idea what this one is...
  hero: PhaseInfo
  heroDeaths: number
  killsElite: number
  killsNormal: number
  totalHealHero: number
  trash: PhaseInfo
  trialBoss: string
}

export type Flags = {
  champTempering: boolean
  initialResources: {
    gems: number
    gold: number
    totalBudget: number
  }
  maxDeltas: {
    champ_level: number
    champ_xp: number
    gems: number
    gold: number
    gross_xp: number
    hero_level: number
    hero_xp: number
  }
  maxGems: number
  // could be interesting
  maxHeroStats: {
    armor: number
    attacksPerSec: number
    cooldownReduction: number
    criticalChance: number
    criticalDamage: number
    deflectRating: number
    dps: number
    extraExperience: number
    life: number
    maxDamage: number
    maxLife: number
    minDamage: number
    power: number
    recovery: number
    toughness: number
  }
  maxItemBudget: number
  // could be good
  playStats: {
    bossKills: number
    deaths: number
    eliteKills: number
    gems: number
    gemstones: number
    gold: number
    kills: number
    // array with 5 numbers, what is it?
    loot: Array<number>,
    playTime: number
    xp: number
  }
  tooManyGemsDetected: boolean
  totalGemsReceived: number
  totalGemsSpent: number
}

export type RankingPayload = {
  champion_level: number
  champion_points: ChampionPoints
  flags: Flags
  hero: Hero
  // hero name, also in hero.name
  name: string
  trialStats: TrialStats
}

export type Ranking = {
  // the MMID, with $class at the end
  challenger: string
  leaderboardId: string
  payload: RankingPayload
  // leaderboard position
  ranking: number
  // it's a number toStringd - seems to be `${TRIAL NUMBER}9${1000 - TRIAL TIME}`
  score: number
}

type GetRankingsRequest = {
  leaderboardId: string
  page: number
  pageSize?: number
  payload?: string
}
export async function getRankings({ leaderboardId, page, pageSize = 1, payload = '*' }: GetRankingsRequest): Promise<Array<Ranking>> {
  const response = await fetch(url(`leaderboards/${leaderboardId}/rankings?page=${page}&pageSize=${pageSize}&payload=${payload}&show_challenger=true`));
  return await response.json();
}
