import { BonusStatsEnchant, Equipped, GearEffect } from 'services/leaderboards';
import { abilityRating, armor, Attribute, criticalRating, findAttributeByName, haste, power, vitality } from './attributes';

export enum Rarity {
  Uncommon = 1,
  Rare,
  Epic,
  Legendary,
}

export enum GemstoneType {
  Ruby = 'ruby',
  Emerald = 'emerald',
  Topaz = 'topaz',
  Diamond = 'diamond',
  Sapphire = 'sapphire',
  Amethyst = 'amethyst',
}

export enum GemstoneLevel {
  Rough = 1,
  Common,
  Teardrop,
  Radiant,
  Trillion,
  Brilliant,
}

export type Gemstone = {
  type: GemstoneType
  level: GemstoneLevel
}

const gemstoneStatValues = [5, 10, 20, 30, 40, 50];
export function gemstoneToStatValue({ type, level }: Gemstone) {
  const value = gemstoneStatValues[level - 1];
  return type === GemstoneType.Diamond ? value * 4 : value;
}

const typeToAttribute = {
  [GemstoneType.Ruby]: power,
  [GemstoneType.Emerald]: vitality,
  [GemstoneType.Topaz]: criticalRating,
  [GemstoneType.Diamond]: armor,
  [GemstoneType.Sapphire]: haste,
  [GemstoneType.Amethyst]: abilityRating,
};

export function gemstoneAttribute(type: GemstoneType) {
  return typeToAttribute[type];
}

function gearEffectIsBonusStat(gearEffect: GearEffect): gearEffect is BonusStatsEnchant {
  return gearEffect.type === 'BonusStats';
}

export function equippedStats(item: Equipped) {
  return [...Array(item.upgrades.length / 2).keys()].map(idx => {
    const stat: string = item.upgrades[2 * idx] as string;
    const value: number = item.upgrades[2 * idx + 1] as number;
    return [findAttributeByName(stat), value] as [Attribute, number];
  });
}

export function mergeStats(stats: Map<Attribute, number>[]) {
  const result = new Map<Attribute, number>();
  stats.forEach(gearStat => {
    for (const [attribute, value] of gearStat) {
      result.set(attribute, (result.get(attribute) ?? 0) + value);
    }
  })
  return result;
}

function statArraysToMap(...statArrays: [Attribute, number][][]) {
  const result = new Map<Attribute, number>();
  statArrays.forEach(arr => arr.forEach(stat => {
    const [attribute, value] = stat;
    result.set(attribute, (result.get(attribute) ?? 0) + value);
  }));
  return result;
}

export function equippedStatsIncludingGemstonesAndEnchants(item: Equipped) {
  const statEnchants = item.gearEffects.filter(gearEffectIsBonusStat).flatMap(effect => effect.params.bonuses.map(bonus => {
    const attribute = findAttributeByName(bonus.stat);
    return [attribute, bonus.amount * attribute.scale] as [Attribute, number];
  }));

  const gemstoneStats = (item.sockets || []).map(socket => {
    const attribute = gemstoneAttribute(socket.gemstone.type);
    const value = gemstoneToStatValue({ type: socket.gemstone.type, level: socket.itemLevel });
    return [attribute, value * attribute.scale] as [Attribute, number];
  });

  const rawStats = equippedStats(item);

  return statArraysToMap(
    statEnchants,
    gemstoneStats,
    rawStats,
  );
}
