import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { BonusStatsEnchant, Equipped, GearEffect } from 'services/leaderboards';
import { formatAttributeValue, minDamage as minDamageStat, maxDamage as maxDamageStat, Attribute, armor } from 'util/attributes';
import { equippedStats, gemstoneAttribute, gemstoneToStatValue, Rarity } from 'util/items';

function BonusStatsEnchantLine({ bonusStatsEnchant }: { bonusStatsEnchant: BonusStatsEnchant }) {
  return (<div>
    {bonusStatsEnchant.params.bonuses.map((bonus, idx) => <div key={idx}>
      +{bonus.amount} {bonus.stat}
    </div>)}
  </div>);
}

function GearEffectLine({ gearEffect }: { gearEffect: GearEffect }) {
  // console.log('gear effect is', gearEffect)
  if (gearEffect.type === 'BonusStats') {
    return (<BonusStatsEnchantLine bonusStatsEnchant={gearEffect as BonusStatsEnchant} />);
  } else if ('params' in gearEffect && 'description' in gearEffect.params) {
    return (<div><span>{gearEffect.params.description}</span></div>);
  } else {
    return (<div>
      <span>no idea how to make this into a gear effect line - {JSON.stringify(gearEffect)}</span>
    </div>);
  }
}

function ItemStatLine({ item, attribute, value }: { item: Equipped, attribute: Attribute, value: number }) {
  const className = attribute === armor ? '' : 'stat';
  const formattedValue = formatAttributeValue(item, attribute, value);
  return (<div className={className}>
    {attribute === armor ? `${attribute.name} ${formattedValue}` : `+${formattedValue} ${attribute.name}`}
  </div>);
}

function DetailedItemStats({ item }: { item: Equipped }) {
  // console.log("item is ", item);
  console.assert(item.upgrades.length % 2 === 0);
  let minDamage = 0;
  let maxDamage = 0;
  const stats = equippedStats(item).filter(sv => {
    const [stat, value] = sv;
    if (stat === minDamageStat) {
      minDamage = value;
    } else if (stat === maxDamageStat) {
      maxDamage = value;
    } else {
      return true;
    }
    return false;
  }).map((sv, idx) => {
    const [stat, value] = sv;
    return (<ItemStatLine key={idx} item={item} attribute={stat} value={value} />);
  });
  const damage = (minDamage && maxDamage) ? `Damage ${minDamage.toFixed(0)} - ${maxDamage.toFixed(0)}` : undefined;
  // an unsocketed socket is present as a null value in the array
  const sockets = (item.sockets || []).map((socket, idx) => socket && <div key={idx} className="stat">
    {socket.gemstone.type} +{gemstoneToStatValue({ type: socket.gemstone.type, level: socket.itemLevel })} {gemstoneAttribute(socket.gemstone.type).name}
  </div>);
  const isCelestial = item.celestial || false;
  // set outta 6 / etc count before enchant
  return item ? (<div>
    <div className={`item-heading quality-${item.quality}`} >
      <div>{item.name}</div>
      <div>Level {item.itemLevel} {Rarity[item.quality]} {item.itemClass}</div>
    </div>
    {damage}
    {stats}
    {sockets}
    <div className="enchant">
      {item.gearEffects?.map((effect, idx) => <div key={idx}>
        <GearEffectLine gearEffect={effect} />
      </div>)}
    </div>
  </div>) : (<div>Nothing equipped here!</div>);
}

function ItemWithDetailsOnHover({ item }: { item: Equipped }) {
  return (<Popup trigger={<div style={{ display: 'inline-block' }}>{item.equippedSlot}</div>} on="hover" position={['right bottom', 'bottom center', 'top center']} >
    <DetailedItemStats item={item} />
  </Popup>);
}

export default ItemWithDetailsOnHover;
