import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { getRankings, Ranking } from 'services/leaderboards';
import { EnhancedLeaderboard, trialLevelFromRanking, trialTimeFromRanking } from 'util/leaderboards';
import Hero from './Hero';
import LeaderboardLine from './LeaderboardLine';
import PaginatedTable from './PaginatedTable';

function Rankings({ leaderboard }: { leaderboard: EnhancedLeaderboard }) {
  const routeMatch = useRouteMatch<{ leaderboardId: string }>();
  const { leaderboardId } = routeMatch.params;
  // const leaderboard = useMemo(() => loadLeaderboardById(leaderboardId), [leaderboardId]);
  // const leaderboard: EnhancedLeaderboard = undefined;

  // console.log('lbid is ', leaderboardId);

  // const [rankings, setRankings] = useState<Ranking | undefined>(undefined);

  // useEffect(() => {
  //   let isActive = true;
  //   getRankings(leaderboardId, 1908, 1).then(rankings => {
  //     if (isActive) {
  //       console.log("setting rankings to ", rankings);
  //       setRankings(rankings);
  //     }
  //   });
  //   return () => {
  //     isActive = false;
  //   };
  // }, [leaderboardId]);

  const columns = React.useMemo<Column<Ranking>[]>(
    () => [
      {
        Header: 'Rank',
        accessor: 'ranking',
        Cell: ({ value }) => <Link to={`${routeMatch.url}/${value}`}>{value}</Link>,
      },
      {
        id: 'class',
        Header: 'Class',
        accessor: (originalRow) => originalRow.payload.hero.class,
      },
      {
        id: 'hero',
        Header: 'Hero',
        accessor: (originalRow) => originalRow.payload.name,
        Cell: (cell) => <Link to={`${routeMatch.url}/${cell.row.original.challenger}`}>{cell.value}</Link>,
      },
      {
        id: 'trialLevel',
        Header: 'Trial Level',
        accessor: (originalRow) => trialLevelFromRanking(originalRow),
      },
      {
        id: 'trialTime',
        Header: 'Time',
        accessor: (originalRow) => trialTimeFromRanking(originalRow),
      },
    ],
    [routeMatch.url]
  );
  const hiddenColumns = React.useMemo(() => leaderboard.description !== 'All Classes' ? ['class'] : [], [leaderboard]);

  // We'll start our table without any data
  const [data, setData] = React.useState<Ranking[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);

  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);

    getRankings({ leaderboardId, page: pageIndex + 1, pageSize }).then(rankings => {
      if (fetchId === fetchIdRef.current) {
        setData(rankings);
        setPageCount(Math.ceil(leaderboard.entriesCount / pageSize));
        setLoading(false);
      }
    });
  }, [leaderboardId, leaderboard.entriesCount]);

  return (
    <Switch>
      <Route path={`${routeMatch.path}/:challenger`}>
        <Hero leaderboard={leaderboard} />
      </Route>
      <Route path={routeMatch.path}>
        <LeaderboardLine leaderboard={leaderboard} />
        <PaginatedTable columns={columns} data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} totalRows={leaderboard.entriesCount} hiddenColumns={hiddenColumns} />
      </Route>
    </Switch>
  );
}

export default Rankings;
