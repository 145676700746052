import { useMemo } from 'react';
import { Column } from 'react-table';
import { DamageFromSource, PhaseInfo, Ranking } from 'services/leaderboards';
import { secondsToReadable, trialLevelFromRanking, trialTimeFromRanking } from 'util/leaderboards';
import BasicTable from './BasicTable';

function TookDamageLine({ phaseInfo }: { phaseInfo: PhaseInfo }) {
  const text = phaseInfo.t0 === 0 && phaseInfo.t1 === 0 ? 'Never took damage' : `First took damage at ${secondsToReadable(phaseInfo.t0)} and last at ${secondsToReadable(phaseInfo.t1)}`;
  return (
    <span>{text}</span>
  )
}

function TrialStats({ ranking }: { ranking: Ranking }) {
  const trialStats = ranking.payload.trialStats;
  const trialLevel = trialLevelFromRanking(ranking);

  const columns = useMemo<Column<DamageFromSource>[]>(
    () => [
      {
        Header: 'Source',
        accessor: 'source',
      },
      {
        Header: 'Average',
        accessor: 'avg',
      },
      {
        Header: 'DPS',
        accessor: 'dps',
      },
      {
        Header: 'Percent',
        accessor: 'percent',
        Cell: ({ value }) => (value * 100).toFixed(2),
      },
      {
        Header: 'Total',
        accessor: 'total',
      },
    ],
    []
  );
  return (
    <div>
      <div>Trial Level {trialLevel} - {trialStats.trialBoss} - completed in {trialTimeFromRanking(ranking)} with {trialStats.heroDeaths} deaths</div>
      <div>Killed {trialStats.killsNormal} normal and {trialStats.killsElite} elite mobs</div>
      <div><TookDamageLine phaseInfo={trialStats.trash} /> from trash</div>
      <div><TookDamageLine phaseInfo={trialStats.boss} /> from boss</div>
      <BasicTable columns={columns} data={trialStats.damageMeter} />
    </div>
  );
}

export default TrialStats;
