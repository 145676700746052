// @ts-nocheck

import { Gemstone, Rarity } from 'util/items';

const STAT_SCALES: {} = {
  gold: 4,
};

type Stats = {
  gold?: number
}

type Jewelry = {
  rarity: Rarity
  gemstone?: Gemstone
  stats: Stats
}

// type FusedStat = {
//   final: number
//   scale: number
//   display: string
// }

const computeStat = (stat: string, stats: Set<string>, j1: Jewelry, j2: Jewelry, j3: Jewelry) => {
  const scale = STAT_SCALES[stat];
  const sj1 = (j1.stats[stat] ?? 0) * scale;
  const sj2 = (j2.stats[stat] ?? 0) * scale;
  const sj3 = (j3.stats[stat] ?? 0) * scale;
  // TODO - gemstones
  const sg1 = 0;
  const sg2 = 0;
  const sg3 = 0;
  const intermediate = Math.floor(Math.max(sj1, sj2, sj3)) + (sj1 + sj2 + sj3) / 15 + (sg1 + sg2 + sg3) / 5;
  const numStats = stats.size;
  const missingStats = j1.rarity + 1 - numStats;
  const bonus = 1 + (0.1 * missingStats / numStats);
  const final = intermediate * bonus / scale;
  // const displayed = Math.round(final);
  // console.log(final);
  return final;
}

const compute = (j1: Jewelry, j2: Jewelry, j3: Jewelry): Jewelry => {
  if (j1.rarity !== j2.rarity || j2.rarity !== j3.rarity) {
    throw new Error('Jewelry must be of same rarity!');
  }

  const stats = new Set<string>();
  [j1, j2, j3].forEach(j => Object.keys(j.stats).forEach(stat => stats.add(stat)));
  if (stats.size > j1.rarity) {
    throw new Error(`Up to ${j1.rarity} different stats allowed, but got ${stats.size}!`);
  }

  const entries = Array.from(stats).map(stat => [stat, computeStat(stat, stats, j1, j2, j3)]);
  const newStats = Object.fromEntries(entries);
  return {
    rarity: j1.rarity + 1,
    stats: newStats,
  };
};

// this one doesn't quite work due to some weird array reference thing!
// https://medium.com/nerd-for-tech/july-2-generating-k-combinations-with-recursion-in-javascript-71ef2b90b44b
// function combinations<T>(collection: T[], combinationLength: number): T[][] {
//   let head, tail, result = [];
//   if (combinationLength > collection.length || combinationLength < 1) { return []; }
//   if (combinationLength === collection.length) { return [collection]; }
//   if (combinationLength === 1) { return collection.map(element => [element]); }
//   for (let i = 0; i < collection.length - combinationLength + 1; i++) {
//     head = collection.slice(i, i + 1);
//     tail = combinations(collection.slice(i + 1), combinationLength - 1);
//     for (let j = 0; j < tail.length; j++) { result.push(head.concat(tail[j])); }
//   }
//   return result;
// }

// /* https://stackoverflow.com/a/54385026 */
function* range(start: number, end: number) {
  for (; start <= end; ++start) { yield start; }
}

function last<T>(arr: T[]) { return arr[arr.length - 1]; }

function* numericCombinations(n: number, r: number, loc: number[] = []): IterableIterator<number[]> {
  const idx = loc.length;
  if (idx === r) {
    yield loc;
    return;
  }
  for (let next of range(idx ? last(loc) + 1 : 0, n - r + idx)) { yield* numericCombinations(n, r, loc.concat(next)); }
}

function* combinations<T>(arr: T[], r: number): Generator<T[], void, unknown> {
  for (let idxs of numericCombinations(arr.length, r)) { yield idxs.map(i => arr[i]); }
}
// /**************** */

const solveRemainder = (memo: Map<[Jewelry, Jewelry, Jewelry], Jewelry>, jewelry: Array<Jewelry>, used: Array<[number, number, number]>, fused: Array<Jewelry>): [Jewelry, Array<[number, number, number]>] => {
  // console.log(`solverem`, jewelry, used, fused);
  if (jewelry.length === used.length * 3) {
    const [j, u] = solveRemainder(memo, fused, [], []);
    return [j, [...used, ...u]];
  } else if (jewelry.length - fused.length * 3 < 3) {
    // console.log(`not enough jewelry left to fuse, returning, fused is `, fused);
    const arr = fused.length > 0 ? fused : jewelry;
    const maxFused = arr.reduce((prev, cur) => prev.stats.gold > cur.stats.gold ? prev : cur);
    return [maxFused, []];
  }
  let maxStat = 0;
  let fuses: Array<[number, number, number]>;
  let maxJewelry: Jewelry;
  for (let i = 0; i < jewelry.length - 2; i++) {
    if (used.some(tuple => tuple.includes(i))) {
      continue;
    }
    for (let j = i + 1; j < jewelry.length - 1; j++) {
      if (used.some(tuple => tuple.includes(j))) {
        continue;
      }
      for (let k = j + 1; k < jewelry.length; k++) {
        if (used.some(tuple => tuple.includes(k))) {
          continue;
        }
        const j1 = jewelry[i];
        const j2 = jewelry[j];
        const j3 = jewelry[k];
        // const entry: [Jewelry, Jewelry, Jewelry] = [j1, j2, j3];
        // const entryJs = JSON.stringify(entry);
        let fusionResult = compute(j1, j2, j3);
        // let fusionResult = memo.get(entryJs);
        // if (fusionResult === undefined) {
        //   fusionResult = compute(j1, j2, j3);
        //   memo.set(entryJs, fusionResult);
        //   // console.log('set memo for ', entry);
        // } else {
        //   // console.log('already had fusion results! for ', entry);
        // }

        // DUH!!! what we really want to memo is this recursive call!!!
        const [crafted, allUsed] = solveRemainder(memo, jewelry, [...used, [i, j, k]], [...fused, fusionResult]);
        // console.log(`from ${JSON.stringify(j1)} ${JSON.stringify(j2)} ${JSON.stringify(j3)} created ${JSON.stringify(crafted)} using ${allUsed}`);
        if (crafted.stats.gold > maxStat) {
          // TODO - want the actual max stat
          maxStat = crafted.stats.gold;
          fuses = allUsed;
          maxJewelry = crafted;
        }
      }
    }
  }
  return [maxJewelry, fuses];
};

const g = (j: Jewelry) => j.stats.gold.toFixed(1);

// [1, 2, 3] => [1, 2, 3]
// [1, 2, 3, 4] => [[[1, 2, 3], [4, 5, 6]], [1, 2, 4], [1, 3, 4], ...]
// memo - map of input jewelry to list of all possible output jewelry
const doSolve = (jewelry: Jewelry[], memo: Map<Jewelry[], Jewelry[][][]>): Jewelry[][][] => {
  if (jewelry.length < 3) {
    return [[]];
  }

  const jewelryJson = JSON.stringify(jewelry);
  const inMemo = memo.get(jewelryJson);
  if (inMemo !== undefined) {
    // console.log(`we have ${jewelryJson} in memo!`);
    return inMemo;
  }
  // generate all combos of 3
  const results: Jewelry[][][] = [];
  const combos = Array.from(combinations(jewelry, 3));
  // console.log("all 3combos: ", JSON.stringify(combos));
  // combos.forEach(c => console.log(c[0].stats.gold, c[1].stats.gold, c[2].stats.gold));
  // console.log('original jewelry ', jewelryJson);
  combos.forEach(combo => {
    // console.log('trying combo ', JSON.stringify(combo), ' from ', jewelryJson);
    const indexes = combo.map(j => [j, jewelry.indexOf(j)]);
    indexes.reverse().forEach(jAndIdx => jewelry.splice(jAndIdx[1], 1));
    // console.log('removed jewelry, its now ', JSON.stringify(jewelry))
    const recursiveResults = doSolve(jewelry, memo);
    // console.log("got recurs results ", JSON.stringify(recursiveResults), ' after taking ', JSON.stringify(combo), ' out of ', jewelryJson)
    recursiveResults.forEach(recursiveResult => {
      const recResWithThisCombo = [combo.map(g)].concat(recursiveResult);

      results.push(recResWithThisCombo);
    });
    // console.log("now the results are ", JSON.stringify(results));
    indexes.reverse().forEach(jewelryAndIndex => {
      const [j, idx]: [Jewelry, number] = jewelryAndIndex;
      jewelry.splice(idx, 0, j);
    });
    // console.log('readded jewelry, its now ', JSON.stringify(jewelry))
  });
  memo.set(jewelryJson, results);
  // console.log(`set memo key $${jewelryJson}`)
  return results;
};

const solve = (jewelry: Array<Jewelry>) => {
  // if (![3, 9, 27].includes(jewelry.length)) {
  //   throw new Error('Must provide number of pieces in power of 3');
  // }
  const memo = new Map<[Jewelry, Jewelry, Jewelry], Jewelry>();
  const result = doSolve(jewelry, memo);
  // console.log("the result is, ", result);
  // result.forEach(js => console.log(`1: ${js[0]}, 2: ${js[1]}`));
  return result;
};

// const gold1 = { rarity: 2, stats: { gold: 58.7 } };
// const gold2 = { rarity: 2, stats: { gold: 55.4 } };
// const gold3 = { rarity: 2, stats: { gold: 55.4 } };

// const o1 = { foo: "bar" }
// const o2 = { hello: "world" }
// const testMemo = new Map();
// const testarr1 = [o1, o2];
// testMemo.set(testarr1, "something");
// const testarr2 = [o1, o2];
// console.log("test memo get, is ", testMemo.get(testarr2));
// // console.log("test memo is ", testMemo)
// console.log("test1 ", testarr1, ", test2: ", testarr2, " are they eq? ", testarr2 == testarr1);

// const gold1 = { rarity: 1, stats: { gold: 44.6 } };
// const gold2 = { rarity: 1, stats: { gold: 44.2 } };
// const gold3 = { rarity: 1, stats: { gold: 43.9 } };
// const gold4 = { rarity: 1, stats: { gold: 44.2 } };
// const gold5 = { rarity: 1, stats: { gold: 44.2 } };
// const gold6 = { rarity: 1, stats: { gold: 44.2 } };
// const gold7 = { rarity: 1, stats: { gold: 44.2 } };
// const gold8 = { rarity: 1, stats: { gold: 44.2 } };
// const gold9 = { rarity: 1, stats: { gold: 44.2 } };

// const gold1 = { rarity: 1, stats: { gold: 38 } };
// const gold2 = { rarity: 1, stats: { gold: 44.2 } };
// const gold3 = { rarity: 1, stats: { gold: 43.9 } };
// const gold4 = { rarity: 1, stats: { gold: 42 } };
// const gold5 = { rarity: 1, stats: { gold: 40 } };
// const gold6 = { rarity: 1, stats: { gold: 38 } };
// const gold7 = { rarity: 1, stats: { gold: 45 } };
// const gold8 = { rarity: 1, stats: { gold: 40 } };
// const gold9 = { rarity: 1, stats: { gold: 36 } };

// // const result = solve([gold1, gold2, gold3]);
// const result = solve([gold1, gold2, gold3, gold4, gold5, gold6, gold7, gold8, gold9]);

const increment = (45 - 36) / 27;
const rings = [...Array(12).keys()].map(num => { return { rarity: 1, stats: { gold: 45 - num * increment } } });
// const res = solve(rings);
// console.log(res);
// solve(rings);

function JewelryCrafting() {
  return (
    <div>
      <button onClick={() => solve(rings)} >solve it</button>
      {/* jewelry! {compute(gold1, gold2, gold3).stats.gold} */}
    </div>
  );
}

export default JewelryCrafting;
