import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';

import './App.css';
import Leaderboards from './components/Leaderboards';
import JewelryCrafting from './components/JewelryCrafting';

function App() {
  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/leaderboards">Leaderboards</Link>
            </li>
            {/* <li>
              <Link to="/jewelry">Jewelry</Link>
            </li> */}
            {/* <li>
              <Link to="/crafting_log">Crafting Log</Link>
            </li> */}
          </ul>
        </nav>

        <Switch>
          <Route path="/leaderboards">
            <Leaderboards />
          </Route>
          <Route path="/jewelry">
            <JewelryCrafting />
          </Route>
          <Route path="/">
            <div>welcome</div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
