import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, Column, UseSortByColumnProps, HeaderGroup } from 'react-table';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';

import { EnhancedLeaderboard, loadLeaderboards } from 'util/leaderboards';
import Rankings from './Rankings';
import 'css/common.css';

function Leaderboards() {
  const routeMatch = useRouteMatch();

  const [leaderboards, setLeaderboards] = useState<EnhancedLeaderboard[]>([]);
  useEffect(() => {
    let isActive = true;
    loadLeaderboards().then((result) => {
      if (isActive) {
        setLeaderboards(result);
      }
    }, (error) => alert('failed to fetch leaderboards! ' + error));
    return () => {
      isActive = false;
    };
  }, []);

  const tableData = React.useMemo(
    () => leaderboards,
    [leaderboards]
  );

  const columns = React.useMemo<Column<EnhancedLeaderboard>[]>(
    () => [
      // {
      //   Header: 'Id',
      //   accessor: 'id',
      // },
      {
        Header: 'Name',
        accessor: 'name',
        // Cell: ({ value }) => <Link to={`${routeMatch.url}/${value}`}>To Leaderboard {value}</Link>,
        // we can pass in the state (leaderboard) here and then retrieve it via `useLocation` in the next component, but we're passing it thru in the <Route> anyway
        // Cell: (cell) => <Link to={{ pathname: `${routeMatch.url}/${cell.row.original.id}`, state: { leaderboard: cell.row.original } }}>{cell.value}</Link>,
        Cell: (cell) => <Link to={`${routeMatch.url}/${cell.row.original.id}`}>{cell.value}</Link>,
      },
      // this one just does PvP vs not
      // {
      //   Header: 'Type',
      //   accessor: 'type',
      // },
      {
        Header: 'Type',
        accessor: 'leaderboardType',
      },
      {
        Header: 'Season',
        accessor: 'season',
      },
      {
        Header: 'Experience',
        accessor: 'experience',
      },
      {
        Header: 'Year',
        accessor: 'year',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      // {
      //   Header: 'gameId',
      //   accessor: 'gameId',
      // },
      {
        Header: 'Players',
        accessor: 'entriesCount',
      },
      // {
      //   Header: 'subLeaderboards',
      //   accessor: ({ subLeaderboards }) => JSON.stringify(subLeaderboards),
      // },
    ],
    [routeMatch.url]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<EnhancedLeaderboard>({ columns, data: tableData }, useSortBy);
  const columnSortIcon = (column) => column.isSorted
    ? column.isSortedDesc
      ? '⬇️'
      : '⬆️'
    : '↕️';

  return (leaderboards.length === 0 && <div>Loading...</div>) || (
    <Switch>
      <Route
        path={`${routeMatch.path}/:leaderboardId`}
        render={(props) => {
          const leaderboardId = props.match.params.leaderboardId;
          const leaderboard = leaderboards.find(lb => lb.id === leaderboardId);
          return leaderboard ? <Rankings leaderboard={leaderboard} /> : <div>Leaderboard {leaderboardId} not found</div>;
        }}
      >
      </Route>
      <Route path={routeMatch.path}>
        <div className='table-container'>
          <table {...getTableProps()} >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: HeaderGroup<EnhancedLeaderboard> & UseSortByColumnProps<EnhancedLeaderboard>) => (
                    // https://react-table.tanstack.com/docs/examples/sorting
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {' ' + columnSortIcon(column)}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Route>
    </Switch>
  );
}

export default Leaderboards;
