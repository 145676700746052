import { Leaderboard, Ranking, getLeaderboards } from 'services/leaderboards';

// import rawLeaderboardsJson from 'leaderboards.json';
// const rawLeaderboards: Leaderboard[] = rawLeaderboardsJson.reverse();

const capFirst = (word: string) => word && (word.charAt(0).toUpperCase() + word.slice(1));

type DerivedLeaderboardProps = {
  leaderboardType?: string,
  season?: string,
  experience?: string,
  year?: string,
}

export type EnhancedLeaderboard = Leaderboard & DerivedLeaderboardProps

const regularSeason = ({ name }: Leaderboard) => {
  const regularSeasonRegex = /(summer|fall|winter|spring)(season)?(\d\d\d\d)(_default)?_(mage|warrior|bountyhunter|all)/;
  const match = name?.match(regularSeasonRegex);
  if (match != null) {
    // console.log(`match of ${name}`, match);
    const season = match[1];
    // const maybeSeasonWord = match[2];
    const year = match[3];
    // const maybeDefaultWord = match[4];
    // const heroClass = match[5];
    return {
      leaderboardType: 'Regular Season',
      season: capFirst(season),
      year,
    };
  }
};

const anb = ({ name }: Leaderboard) => {
  const anbRegex = /anb_(bronze|silver|gold)_(\d*)_(contender|veteran|master)?/;
  const match = name?.match(anbRegex);
  if (match != null) {
    const type = match[1];
    const number = match[2];
    const experience = match[3];
    return {
      leaderboardType: 'ANB',
      season: capFirst(type),
      experience: capFirst(experience),
      year: number,
    };
  }
}

const newBeginning = ({ name }: Leaderboard) => {
  const anbRegex = /new_beginning_(\d)/;
  const match = name?.match(anbRegex);
  if (match != null) {
    const type = match[1];
    return {
      leaderboardType: 'ANB',
      season: type,
    };
  }
}

const warSupplies = ({ name }: Leaderboard) => {
  if (name?.startsWith('war_supplies')) {
    return {
      leaderboardType: 'War Supplies',
    };
  }
}

const pvp = ({ name, description }: Leaderboard) => {
  if (name?.includes('PvP') || description?.includes('PvP')) {
    return {
      leaderboardType: 'PvP',
    };
  }
}

const enhanceLeaderboard = (leaderboard: Leaderboard): DerivedLeaderboardProps => regularSeason(leaderboard) || anb(leaderboard) || newBeginning(leaderboard) || warSupplies(leaderboard) || pvp(leaderboard) || {};

export async function loadLeaderboards(): Promise<EnhancedLeaderboard[]> {
  const rawLeaderboards = await getLeaderboards();
  return rawLeaderboards.reverse()
    .filter(leaderboard => leaderboard.entriesCount > 0 && !leaderboard.description?.includes(' Test '))
    .map(leaderboard => { return { ...leaderboard, ...enhanceLeaderboard(leaderboard) } });
}

export const loadLeaderboard = (leaderboard: Leaderboard): EnhancedLeaderboard => { return { ...leaderboard, ...enhanceLeaderboard(leaderboard) }; };

export async function loadLeaderboardById(leaderboardId: string): Promise<EnhancedLeaderboard> {
  return (await loadLeaderboards()).find(leaderboard => leaderboard.id === leaderboardId);
}

export const trialLevelFromRanking = (ranking: Ranking) => Number(String(ranking.score).slice(0, -4));

export const secondsToReadable = (seconds: number) => new Date(seconds * 1000).toISOString().substr(15, 4);

export const trialTimeFromRanking = (ranking: Ranking) => secondsToReadable(9999 - (ranking.score % 10000));
