import { Equipped } from 'services/leaderboards'

export type Attribute = {
  name: string
  scale: number
  decimals: number
  percent: boolean
  aka?: string
}

export const minDamage: Attribute = {
  name: 'Minimum Damage',
  scale: 1,
  decimals: 0,
  percent: false,
  aka: 'mindamage',
}

export const maxDamage: Attribute = {
  name: 'Minimum Damage',
  scale: 1,
  decimals: 0,
  percent: false,
  aka: 'maxdamage',
}

export const abilityRating: Attribute = {
  name: 'Ability Rating',
  scale: 2,
  decimals: 0,
  percent: false,
}

export const arcaneDamage: Attribute = {
  name: 'Arcane Damage',
  scale: 12,
  decimals: 1,
  percent: true,
}

export const armor: Attribute = {
  name: 'Armor',
  scale: 1,
  decimals: 0,
  percent: false,
}

export const blockRating: Attribute = {
  name: 'Block Rating',
  scale: 1.5,
  decimals: 0,
  percent: false,
}

export const criticalDamage: Attribute = {
  name: 'Critical Damage',
  scale: 3,
  decimals: 1,
  percent: true,
}

export const criticalRating: Attribute = {
  name: 'Critical Rating',
  scale: 2,
  decimals: 0,
  percent: false,
}

export const deflectRating: Attribute = {
  name: 'Deflect Rating',
  scale: 0.5,
  decimals: 0,
  percent: false,
}

export const dodgeRating: Attribute = {
  name: 'Dodge Rating',
  scale: 2,
  decimals: 0,
  percent: false,
}

export const experience: Attribute = {
  name: 'Experience',
  scale: 4,
  decimals: 1,
  percent: true,
}

export const extraGold: Attribute = {
  name: 'Extra Gold',
  scale: 4,
  decimals: 1,
  percent: true,
}

export const fireDamage: Attribute = {
  name: 'Fire Damage',
  scale: 12,
  decimals: 1,
  percent: true,
}

export const frostDamage: Attribute = {
  name: 'Frost Damage',
  scale: 12,
  decimals: 1,
  percent: true,
}

export const haste: Attribute = {
  name: 'Haste',
  scale: 2,
  decimals: 0,
  percent: false,
}

export const lifeOnHit: Attribute = {
  name: 'Life On Hit',
  scale: 0.5,
  decimals: 0,
  percent: false,
}

export const lifeRegeneration: Attribute = {
  name: 'Life Regeneration',
  scale: 5 / 6.0,
  decimals: 0,
  percent: false,
  aka: 'lifeRegen',
}

export const movementSpeed: Attribute = {
  name: 'Movement Speed',
  scale: 10 / 3, // TODO - verify
  decimals: 0,
  percent: false,
}

export const natureDamage: Attribute = {
  name: 'Nature Damage',
  scale: 12,
  decimals: 1,
  percent: true,
}

export const parryRating: Attribute = {
  name: 'Parry Rating',
  scale: 2,
  decimals: 0,
  percent: false,
}

export const physicalDamage: Attribute = {
  name: 'Physical Damage',
  scale: 12,
  decimals: 1,
  percent: true,
}

export const power: Attribute = {
  name: 'Power',
  scale: 2,
  decimals: 0,
  percent: false,
}

export const vitality: Attribute = {
  name: 'Vitality',
  scale: 2,
  decimals: 0,
  percent: false,
}

export const attributes = [
  minDamage,
  maxDamage,
  abilityRating,
  arcaneDamage,
  armor,
  blockRating,
  criticalDamage,
  criticalRating,
  deflectRating,
  dodgeRating,
  experience,
  extraGold,
  fireDamage,
  frostDamage,
  haste,
  lifeOnHit,
  lifeRegeneration,
  movementSpeed,
  natureDamage,
  parryRating,
  physicalDamage,
  power,
  vitality,
];

const attributeNameMatches = (attributeNameLower: string) => {
  return (attribute: Attribute) => {
    const gameName = attribute.name.replace(/\s+/g, '').toLowerCase();
    return gameName === attributeNameLower || attribute.aka === attributeNameLower || `base${gameName}` === attributeNameLower;
  };
};

export const findAttributeByName = (attributeName: string): Attribute | undefined => {
  const attributeNameLower = attributeName.toLowerCase();
  const attr = attributes.find(attributeNameMatches(attributeNameLower));
  if (!attr) {
    console.warn(`Unable to determine attribute from name ${attributeName}`);
  }
  return attr;
};

export const formatAttributeValue = (item: Equipped, attr: Attribute, value: number) => {
  const scale = attr.scale || 1;
  // for jewelry, show extra decimals so folks can see true values
  const decimals = (item.itemClass === 'Ring' || item.itemClass === 'Necklace') ? 2 : attr.decimals || 0;
  const percent = attr.percent || false;
  const pct = percent ? '%' : '';
  const scaledAndFixedValue = (value / scale).toFixed(decimals);
  return `${scaledAndFixedValue}${pct}`;
};
