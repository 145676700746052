import { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import type { ChallengerRanking, Ranking, RankingPayload } from 'services/leaderboards';
import { getChallengerRanking, getRankings } from 'services/leaderboards';
import { EnhancedLeaderboard } from 'util/leaderboards';
import './Hero.css';

import { CraftingLogForRanking } from './CraftingLogByChallengerOrPosition';
import LeaderboardLine from './LeaderboardLine';
import TrialStats from './TrialStats';
import ItemWithDetailsOnHover from './ItemWithDetailsOnHover';
import StatSheet from './StatSheet';

function HeroDetails({ rankingPayload }: { rankingPayload: RankingPayload }) {
  // unfortunately no stat summary, will need to add up all gear + all passives
  return (<div>
    <span>hero details stuff here</span>
  </div>);
}

function HeroItemsOverview({ rankingPayload }: { rankingPayload: RankingPayload }) {
  return (<div>
    <button>Level {rankingPayload.champion_level} Champion</button>

    {rankingPayload.hero.equipped.map((item, idx) => <div key={idx}><ItemWithDetailsOnHover item={item} /></div>)}
    <div>
      <div style={{ color: 'red' }}>{Math.round(rankingPayload.hero.DPS).toLocaleString()} Damage</div>
      <div style={{ color: 'green' }}>{Math.round(rankingPayload.hero.Toughness).toLocaleString()} Toughness</div>
      <div style={{ color: 'blue' }}>{Math.round(rankingPayload.hero.Recovery).toLocaleString()} Recovery</div>
    </div>
    <Popup trigger={<button>Details</button>} position="right center">
      <HeroDetails rankingPayload={rankingPayload} />
    </Popup>

  </div>);
}

function ChallengerStats({ ranking, leaderboard }: { ranking: Ranking, leaderboard: EnhancedLeaderboard }) {
  const routeMatch = useRouteMatch<{ leaderboardId: string }>();
  const { leaderboardId } = routeMatch.params;
  const data = ranking.payload;
  return (<div style={{ width: '100%' }}>
    <LeaderboardLine leaderboard={leaderboard} includeLink={true} />
    <Link to={`/leaderboards/${leaderboardId}/${ranking.challenger}`}>{data.hero.name}</Link>

    <Tabs>
      <TabList>
        <Tab>Hero</Tab>
        <Tab>Trial</Tab>
        <Tab>Stat Sheet [WIP]</Tab>
        <Tab>Crafting</Tab>
      </TabList>

      <TabPanel>
        <div>Rank {ranking.ranking}</div>
        <div>{data.hero.name}</div>
        <span>Level {data.hero.level} {data.hero.class}</span>
        <HeroItemsOverview rankingPayload={data} />
      </TabPanel>
      <TabPanel>
        <TrialStats ranking={ranking} />
      </TabPanel>
      <TabPanel>
        <StatSheet ranking={ranking} />
      </TabPanel>
      <TabPanel>
        <CraftingLogForRanking ranking={ranking} />
      </TabPanel>
    </Tabs>
  </div>);
}

function Hero({ leaderboard }: { leaderboard: EnhancedLeaderboard }) {
  const routeMatch = useRouteMatch<{ leaderboardId: string, challenger: string }>();
  const { leaderboardId, challenger } = routeMatch.params;

  const [challengerRanking, setChallengerRanking] = useState<ChallengerRanking | undefined>(undefined);
  useEffect(() => {
    let isActive = true;
    getChallengerRanking(leaderboardId, challenger).then(challengerRanking => {
      if (isActive) {
        setChallengerRanking(challengerRanking);
      }
    });
    return () => {
      isActive = false;
    };
  }, [leaderboardId, challenger]);

  const [ranking, setRanking] = useState<Ranking | undefined>(undefined);
  useEffect(() => {
    let isActive = true;
    challengerRanking && getRankings({ leaderboardId, page: challengerRanking.ranking }).then(rankings => {
      if (isActive) {
        setRanking(rankings[0]);
      }
    });
    return () => {
      isActive = false;
    };
  }, [leaderboardId, challenger, challengerRanking]);

  return ranking ? (<ChallengerStats leaderboard={leaderboard} ranking={ranking} />) : (<div>Loading...</div>);
}

export default Hero;
