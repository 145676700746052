import { Ranking } from 'services/leaderboards';

const pythiaBaseUrl = 'http://pythia.epizy.com';

export function CraftingLogForRanking({ ranking }: { ranking: Ranking }) {
  const mcd = ranking.payload.hero.masterCraftDice;
  const craftSequence = [...Array(7).keys()].map(num => Array((mcd?.h[num] ?? 0) + 1).join(String(num + 1))).join('');
  const pythiaUrl = `${pythiaBaseUrl}/?crafts_sequence=${craftSequence}`

  return (
    <div>
      <div>Crafted {mcd?.nRolls} lvl70+ legendary set or uniques</div>
      {[...Array(7).keys()].map(num => <div key={num}>{71 + num} - {mcd?.h[num]}</div>)}
      <div><button onClick={() => navigator.clipboard.writeText(craftSequence)}>copy pythia pattern to clipboard</button></div>
      <div><a href={pythiaUrl} target="_blank" rel="noopener noreferrer">Open in Pythia</a></div>
    </div>
  );
}
