import { HeroClass, MedalId, Ranking, RankingPayload } from 'services/leaderboards';
import { armor, Attribute, vitality } from 'util/attributes';
import { equippedStatsIncludingGemstonesAndEnchants, mergeStats } from 'util/items';
import { fortitudeArmorBonuses, tenacityVitalityBonuses } from 'util/medals';

type StatSheetAttribute = {
  name: string
  attribute: Attribute
  valueDerivation?: (rankingPayload: RankingPayload, value: number) => number
  description: (rankingPayload: RankingPayload, value: number) => string
  expandedDescription: (rankingPayload: RankingPayload, value: number) => string
}

const getMedalsValue = (rankingPayload: RankingPayload, medalID: MedalId, medalValues: number[]) => {
  const medals = rankingPayload.hero.medalsInfo.find(medal => medal.medalID === medalID);
  let medalLevel = medals.level || 0;
  if (medalLevel >= medalValues.length) {
    console.warn(`Level ${medalLevel} ${medalID} medal not supported, only known up to ${medalValues.length}`);
    medalLevel = medalValues.length - 1;
  }
  return medalValues[medalLevel];
}

const armorMultiplier = (rankingPayload: RankingPayload) => {
  const usingToughAsNails = rankingPayload.hero.quickbar.find(skill => skill.ability === 'toughasnails');
  const tanArmor = usingToughAsNails ? rankingPayload.hero.abilityLevel.toughasnails / 100 + 0.1 : 0;

  const armorCLs = rankingPayload.champion_points.armorPercent / 200;

  const numWarriorCompanions = rankingPayload.hero.team.filter(companion => companion.type === 'boris_norse' || companion.type === 'norgrim').length;

  return 1 + tanArmor + armorCLs + 0.07 * numWarriorCompanions;
}

const armorFormula = (rankingPayload: RankingPayload, value: number) => {
  // TODO - want to break this up for gear vs enchants vs medals etc
  const armorFromMedals = getMedalsValue(rankingPayload, MedalId.Fortitude, fortitudeArmorBonuses);

  const armorFromClass = rankingPayload.hero.class === HeroClass.Warrior ? 120 * rankingPayload.hero.level : 0;
  const totalBaseArmor = armorFromMedals + 50 + armorFromClass + (value / armor.scale);
  const multiplier = armorMultiplier(rankingPayload);
  return totalBaseArmor * multiplier;
};

const armorK = (rankingPayload: RankingPayload) => (2000 + 1450 * rankingPayload.hero.level) / 69;

const armorPct = (rankingPayload: RankingPayload, value: number) => {
  const mitigation = value / (armorK(rankingPayload) + value);
  return (mitigation * 100).toFixed(1);
}

const vitalityFormula = (rankingPayload: RankingPayload, value: number) => {
  const vitalityFromMedals = getMedalsValue(rankingPayload, MedalId.Tenacity, tenacityVitalityBonuses);

  const synergyLevel = Object.values(rankingPayload.hero.abilityLevel).reduce((sum, cur) => sum + cur - 1, 0);
  const vitalitySynergyLevel = synergyLevel === 0 ? 0 : (Math.floor(synergyLevel / 10) + 1);
  const vitalityFromSynergy = vitalitySynergyLevel * 5;
  // Ability Rating = 5 × ( synergy ÷ 10 )
  // Attack Speed = max( 0 ; ( synergy - 10 ) ÷ 20 )%

  return vitalityFromMedals + vitalityFromSynergy + (value / vitality.scale);
}

const lifeFromVitality = (value: number) => (65 * value).toFixed(0);

const defensiveStats: StatSheetAttribute[] = [
  {
    name: 'Vitality',
    attribute: vitality,
    valueDerivation: (rp, value) => vitalityFormula(rp, value),
    description: (rp, value) => `life increased by ${lifeFromVitality(value)}`,
    expandedDescription: (rp, value) => `For each point of vitality, your HP is increased by 65. Your total Health Points increased by ${lifeFromVitality(value)}.`,
  },
  // TOTAL LIFE
  {
    name: 'Armor',
    attribute: armor,
    valueDerivation: (rp, value) => armorFormula(rp, value),
    description: (rp, value) => `${armorPct(rp, value)}% less damage taken`,
    expandedDescription: (rp, value) => `Reduces all damage taken from enemies of the same level as you by ${armorPct(rp, value)}%.`
  },
  // {
  //   name: 'Block Rating',
  //   attribute: blockRating,
  //   description: (rp, value) => '${}% chance when hit',
  //   expandedDescription: (rp, value) => 'You have a ${}% chance to block an incoming attack.'
  // },
  // {
  //   name: 'Parry Rating',
  //   attribute: parryRating,
  //   description: (rp, value) => '${}% chance when hit',
  //   expandedDescription: (rp, value) => 'You have a ${}% chance to parry an incoming attack.'
  // },
  // {
  //   name: 'Dodge Rating',
  //   attribute: dodgeRating,
  //   description: (rp, value) => '${}% chance when hit',
  //   expandedDescription: (rp, value) => 'You have a ${}% chance to dodge an incoming attack.'
  // },
];

function StatLine({ rankingPayload, attribute, value }: { rankingPayload: RankingPayload, attribute: StatSheetAttribute, value: number }) {
  const derivedValue = attribute.valueDerivation ? attribute.valueDerivation(rankingPayload, value) : value / attribute.attribute.scale;
  return (
    <div>{derivedValue.toFixed(attribute.attribute.decimals)} {attribute.attribute.name} {attribute.description(rankingPayload, derivedValue)}........ {attribute.expandedDescription(rankingPayload, derivedValue)}</div>
  );
}

function StatGroup({ rankingPayload, totalGearStats, attributes }: { rankingPayload: RankingPayload, totalGearStats: Map<Attribute, number>, attributes: StatSheetAttribute[] }) {
  // TODO - flip this so we iterate over `attributes` to preserve order
  const relevantGearStats = Array.from(totalGearStats).map((stat) => {
    const [attribute, value] = stat;
    const statSheetAttribute = attributes.find(statSheetAttribute => statSheetAttribute.attribute === attribute);
    const tuple: [StatSheetAttribute, number] = [statSheetAttribute, value];
    return tuple;
  }).filter(stat => stat[0] !== undefined);
  return (<div>
    {Array.from(relevantGearStats).map((attrValue, idx) => <StatLine key={idx} rankingPayload={rankingPayload} attribute={attrValue[0]} value={attrValue[1]} />)}
  </div>);
}

function StatSheet({ ranking }: { ranking: Ranking }) {
  // add up all the stats on gear (including enchants + gemstones) + abilities + CL + synergy, and haste or something from dual weapons?
  // gear, gemstones, enchants, set bonuses, passive skills, synergy, medals 
  const gearStats = ranking.payload.hero.equipped.map(equipped => equippedStatsIncludingGemstonesAndEnchants(equipped));
  const totalGearStats = mergeStats(gearStats);

  return (<div>
    <div style={{ color: 'green' }}>
      <StatGroup rankingPayload={ranking.payload} totalGearStats={totalGearStats} attributes={defensiveStats} />
    </div>
  </div>);
}

export default StatSheet;
